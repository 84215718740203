<!--  -->
<template>
  <div>
    <el-form :inline="true"
             :model="form"
             class="demo-form-inline">
      <el-form-item label="账号">
        <el-input v-model="form.UserName"
                  placeholder="请输入账号"></el-input>
      </el-form-item>

      <el-form-item label="分组">
        <el-select v-model="form.AdminGroupId"
                   placeholder="请选择分组">
          <el-option v-for="item in group"
                     :key="item.id"
                     :label="item.gname"
                     :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="getUser">查询</el-button>
        <el-button type="primary"
                   @click="showAddUser">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table ref="table"
              :data="userList"
              border
              style="width: 100%"
              height="80vh"
              row-key="userID"
              stripe>
      <el-table-column prop="UserName"
                       label="账号"></el-table-column>
      <el-table-column prop="TrueName"
                       label="姓名"> </el-table-column>
      <el-table-column prop="gname"
                       label="分组"> </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <el-switch v-model="scope.row.status"
                     @change="editUser(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small"
                     type="text"
                     @click="showResetPWD(scope.row)">重设密码</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="新增账号"
               v-model="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="分组"
                      label-width="120px">
          <el-select v-model="addForm.AdminGroupId"
                     placeholder="请选择分组">
            <el-option v-for="item in formGroup"
                       :key="item.id"
                       :label="item.gname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="账号"
                      label-width="120px">
          <el-input v-model="addForm.UserName"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="姓名"
                      label-width="120px">
          <el-input v-model="addForm.TrueName"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="密码"
                      label-width="120px">
          <el-input v-model="addForm.PassWord"
                    autocomplete="off"
                    show-password></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="addUser">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Api } from "@/assets/Api";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      dialogFormVisible: false,
      form: {
        UserName: "",
        AdminGroupId: 0,
      },
      userList: [],
      group: [],
      formGroup: [],
      addForm: {
        userID: 0,
        UserName: "",
        TrueName: "",
        PassWord: "",
        AdminGroupId: 0,
        status: true,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showResetPWD (row) {
      this.addForm = {
        userID: row.userID,
        UserName: row.UserName,
        TrueName: row.TrueName,
        PassWord: "",
        AdminGroupId: row.AdminGroupId,
        status: row.status,
      };
      this.dialogFormVisible = true;
    },
    showAddUser () {
      this.addForm = {
        userID: 0,
        UserName: "",
        TrueName: "",
        PassWord: "",
        AdminGroupId: 0,
        status: true,
      };
      this.dialogFormVisible = true;
    },
    addUser () {
      if (this.addForm.userID == 0) {

        Api.Api2.AddGHAdmin(this.addForm).then((res) => {
          if (res.code == "0") {
            this.$message.success(res.data);
            this.getUser();
            this.dialogFormVisible = false;
          } else {
            this.$message.warning(res.data);
          }
        });
      } else {
        Api.Api2.EditGHAdmin(this.addForm).then((res) => {
          if (res.code == "0") {
            this.$message.success(res.data);
            this.getUser();
            this.dialogFormVisible = false;
          } else {
            this.$message.warning(res.data);
          }
        });
      }
    },
    GetGHGroup () {
      Api.Api2.GHGroupList().then((res) => {
        if (res.code == 0) {
          this.group = res.data;
          this.group.push({ id: 0, gname: "全部" });
          this.formGroup = res.data;
          this.formGroup.push({ id: 0, gname: "请选择分组" });
        }
      });

    },
    getUser () {
      Api.Api2.GHAdminList(this.form).then((res) => {
        if (res.code == 0) {
          this.userList = res.data;
          this.userList.forEach((item, index) => {
            if (item.status == 0) {
              this.userList[index].status = true;
            } else {
              this.userList[index].status = false;
            }
          });
        }
      });
    },
    editUser (row) {
      console.info(row);
      row.PassWord = "";
      Api.Api2.EditGHAdmin(row).then((res) => {
        if (res.code == "0") {
          this.$message.success(res.data);
          this.getUser();
        } else {
          this.$message.warning(res.data);
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.GetGHGroup();
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>